import {Hero} from "../hero/hero";
import {Container, Space} from "@mantine/core";
import {ProductCarousel} from "./carousal";
import {BrandCta} from "./brandCta";
import {useStyles} from "./styles";

export default function Home() {
    const {classes} = useStyles();
    return <>
        <Hero/>
        <Space h={"xs"}/>
        <BrandCta/>
        <Space h={"xs"}/>
        <Container className={classes.showcase}>
            <ProductCarousel/>
        </Container>
        {/*<Space h={"xl"}/>*/}
    </>
}