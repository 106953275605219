import {ButtonStylesParams, MantineProvider, MantineThemeOverride, rem} from "@mantine/core";
import React from "react";

export const theme: MantineThemeOverride = {
    globalStyles: (theme) => ({
        body: {
            ...theme.fn.fontStyles(),
            backgroundColor: theme.colors.brand[0],
            color: theme.black,
            lineHeight: theme.lineHeight,
        },
    }),
    colorScheme: "light",
    colors: {
        brand: ["#EBE7D9", "#DCD1AD", "#D5C281", "#D7B952", "#BDA13F", "#968340", "#786B3E", "#61583A", "#4F4934", "#413D2F"],
    },
    primaryColor: 'brand',
    primaryShade: {light: 6, dark: 8}, //#f76707, #ff922b, #d9480f
    defaultGradient: {from: 'brand.8', to: 'brand.6', deg: 20},
    defaultRadius: "xs",
    fontFamily: `proxima-nova, sans-serif`,
    components: {
        Button: {
            // Subscribe to theme and component params
            styles: (theme, params: ButtonStylesParams, {variant}) => ({
                root: {
                    backgroundColor:
                        variant === 'outline'
                            ? theme.colors.brand[0]
                            : theme.colors.brand[4],
                    color:
                        variant === 'outline'
                            ? theme.colors.brand[8]
                            : theme.white,
                    borderColor:
                        variant === 'outline'
                            ? theme.colors.brand[9]
                            : undefined,
                    fontWeight: 500,
                    fontSize: theme.fontSizes.sm,
                    '&:hover': {
                        backgroundColor:
                            variant === 'outline'
                                ? theme.colors.brand[0]
                                : theme.colors.brand[5],
                        color:
                            variant === 'outline'
                                ? theme.colors.brand[9]
                                : theme.white,
                        borderColor:
                            variant === 'outline'
                                ? theme.colors.brand[3]
                                : undefined,
                        fontWeight: 400,
                    },
                }
            }),
        },
        Header: {
            styles: (theme, {variant}) => ({
                root: {
                    backgroundColor: theme.colors.brand[0],
                }
            }),
        },
        Text: {
            styles: (theme, {variant}) => ({
                root: {
                    color: theme.colors.brand[9],
                }
            }),
        },
        AppShell: {
            styles: (theme, {variant}) => ({
                main: {
                    padding: 0,
                }
            }),
        }
    }
}

interface ThemeProviderProps {
    children: React.ReactNode;
}

export function ThemeProvider({children}: ThemeProviderProps) {
    return (
        <MantineProvider withGlobalStyles withNormalizeCSS withCSSVariables theme={theme}>
            {children}
        </MantineProvider>
    );
}
