import data from './data.json';

export interface Product {
    images: string[];
    price: string;
    collection: string;
    description: string;
    longDescription: string;
}

export function getCtaTexts(): Array<string> {
    return [data.ctaHeading, data.ctaBody];
}

export function getHeroProductId(): number {
    return data.heroProductId;
}

export function loadHeroImage(): string {
    return data.heroImageUrl;
}

export function loadHeaderLogo(): string {
    return data.headerLogoUrl;
}

export function loadFooterLogo(): string {
    return data.footerLogoUrl;
}

export function loadAllDesigns(): Promise<Product[]> {
    return new Promise<Product[]>((resolve, reject) => {
        setTimeout(() => {
            resolve(data.products);
        }, 500);
    });
}

export function loadHomePageDesigns(): Promise<Product[]> {
    return new Promise<Product[]>((resolve, reject) => {
        setTimeout(() => {
            resolve(data.products.slice(0, 5));
        }, 500);
    });
}