import { Image } from '@mantine/core';
import { CSSProperties } from 'react';

export interface LogoProps {
  size?: string,
  height: string,
  width?: string,
  imageUrl: string,
  onClick?: () => void,
  style?: CSSProperties;
}

export function Logo(props: LogoProps) {
  return <>
    <Image maw={"100%"}
      radius='md'
      height={props.height}
      width={props.width || props.height}
      src={props.imageUrl}
      onClick={(event) => { props.onClick ? props.onClick() : event.preventDefault(); }}
      style={props.style}
    />
  </>
}