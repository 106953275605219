import { useStyles } from "./styles";
import { Button, Stack, Text } from "@mantine/core";
import { useNavigate } from "react-router-dom";
import { getCtaTexts } from "../../data/data";

export interface BrandDescriptionProps {

}

export const BrandCta = (props: BrandDescriptionProps) => {
    const { classes } = useStyles();
    const navigate = useNavigate();
    const cta = getCtaTexts();
    return <>
        <Stack align={"center"} justify={"center"} spacing={"sm"} className={classes.wrapper}>
            {cta[0] && <Text className={classes.ctaHeading}>
                {cta[0]}
            </Text>}
            {cta[0] && <Text className={classes.ctaText}>
                {cta[1]}
            </Text>}
            <Button className={classes.ctaButton} variant="filled" size="lg" onClick={(event) => {
                event.preventDefault();
                navigate('/shop');
            }}>
                Shop Rongphul
            </Button>
        </Stack>
    </>
}