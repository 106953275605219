import { Text, Container, ActionIcon, Group, rem } from '@mantine/core';
import { IconBrandInstagram, IconBrandWhatsapp } from '@tabler/icons-react';
import { useStyles } from "./styles";
import { Logo } from '../logo/logo';
import { loadFooterLogo } from '../../data/data';

interface FooterLinksProps {
    data: {
        title: string;
        links: { label: string; link: string }[];
    }[];
}

function getData(classes: any) {
    return [
        {
            title: "Connect with us",
            links: [
                {
                    label: "Instagram",
                    component: <Group spacing={"0.1rem"} position="left" noWrap>
                        <IconBrandInstagram size="2rem" stroke={1.5} />
                        <Text<'a'>
                            fz="lg"
                            className={classes.link}
                            component="a"
                            target='_blank'
                            href="https://instagram.com/rongphul/"
                        >
                            Instagram
                        </Text>
                    </Group>
                },
                {
                    label: "WhatsApp",
                    component: <Group spacing={"0.1rem"} position="left" noWrap>
                        <IconBrandWhatsapp size="2rem" stroke={1.5} />
                        <Text<'a'>
                            fz="lg"
                            className={classes.link}
                            component="a"
                            target='_blank'
                            href="https://api.whatsapp.com/send?phone=918628209754&text=Hi"
                        >
                            WhatsApp
                        </Text>
                    </Group>

                }
            ]
        }
    ]
}


export function BrandFooter() {
    const { classes } = useStyles();
    const data = getData(classes);
    const groups = data.map((group) => {
        const links = group.links.map((link, index) => (
            <div key={index}>{link.component}</div>
        ));

        return (
            <div className={classes.wrapper} key={group.title}>
                <Text className={classes.title}>{group.title}</Text>
                {links}
            </div>
        );
    });
    const imageUrl = loadFooterLogo();
    return (
        <footer className={classes.footer}>
            <Container className={classes.inner}>
                <div className={classes.logo}>
                    <Logo size='xl' height={rem(60)} imageUrl={imageUrl}/>
                    <Text size="sm" color="dimmed" className={classes.description}>
                        Slow Made sustainable beaded Jewellery
                    </Text>
                    <Text size="sm" color="dimmed" className={classes.description}>
                        Made to order(Dispatch 5-7 days)
                    </Text>
                    <Text size="sm" color="dimmed" className={classes.description}>
                        Color customisations available
                    </Text>
                </div>
                <div className={classes.groups}>{groups}</div>
            </Container>
            <Container className={classes.afterFooter}>
                <Text color="dimmed" size="sm">
                    © 2023 Rongphul. All rights reserved.
                </Text>
            </Container>
        </footer>
    );
}