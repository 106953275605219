import {
    Container, Card, Group,
    Button, Text, SimpleGrid,
    Image, Space, Grid,
    rem, useMantineTheme,
    Stack, Flex, LoadingOverlay
} from "@mantine/core";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { Product, loadAllDesigns } from "../../data/data";
import { useNavigate } from "react-router-dom";
import { IconBrandWhatsapp } from "@tabler/icons-react";

function SingleProduct({ itemId }: any) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(true);
    const [product, setProduct] = useState<Product>();
    const PRIMARY_HEIGHT = rem(600);
    const theme = useMantineTheme();
    const SECONDARY_HEIGHT = `calc(2*(${PRIMARY_HEIGHT} / 3) - ${theme.spacing.md} / 2)`;
    const TERTIARY_HEIGHT = `calc(${PRIMARY_HEIGHT} / 3 - ${theme.spacing.md} / 2)`;
    useEffect(() => {
        let once = false;
        loadAllDesigns()
            .then((p: Product[]) => {
                if (!once) {
                    setProduct(p[Number.parseInt(itemId)]);
                    setLoading(false);
                }
            });
        return () => {
            once = true;
        };
    }, []);

    const host = process.env.HOST_URL || 'https://giftcrafts.in';
    const whatsAppMsg = `Hi. I am interested in ${product?.collection}. ${host + '/shop/' + itemId}`;

    return <>
        <LoadingOverlay
            visible={loading}
            zIndex={1000}
            overlayBlur={2}
            radius={"sm"}
        />

        {product && <>
            <Container my="xs">
                <SimpleGrid cols={2} spacing="md" breakpoints={[{ maxWidth: 'sm', cols: 1 }]}>
                    <Grid gutter="md">
                        <Grid.Col>
                            <Image
                                // className={classes.productImage}
                                src={product.images[0]}
                                height={PRIMARY_HEIGHT}
                            />
                        </Grid.Col>
                        {
                            product.images.slice(1).map((img, idx) => {
                                return <Grid.Col span={6} key={idx}>
                                    <Image
                                        src={img}
                                        height={TERTIARY_HEIGHT}
                                    />
                                </Grid.Col>
                            })
                        }
                    </Grid>
                    <Grid gutter="md">
                        <Grid.Col>
                            <Card shadow="sm" padding="lg" radius="sm" withBorder mah={PRIMARY_HEIGHT}>
                                <Stack justify="space-between" h={`calc(${PRIMARY_HEIGHT} - 2*${theme.spacing.lg})`}>
                                    <Flex justify="flex-end"
                                        align="flex-start"
                                        direction="column"
                                        wrap="wrap"
                                    >
                                        <Text size="xl" fw={700}>
                                            {product.collection}
                                        </Text>
                                        <Space h="md" />
                                        <Text size="lg" color="dimmed" style={{ whiteSpace: "pre-wrap" }}>
                                            {product.longDescription}
                                        </Text>
                                    </Flex>
                                    <Group mt={"md"} position="right" spacing={"lg"}>
                                        <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
                                            ₹{product.price}
                                        </Text>
                                        <Button variant="light"
                                            leftIcon={<IconBrandWhatsapp size={20} />}
                                            color="blue"
                                            radius="md"
                                            style={{ flex: 1 }}
                                            component="a"
                                            target="_blank"
                                            fw={700}
                                            fz="lg"
                                            href={`https://api.whatsapp.com/send?phone=918638209754&text=${whatsAppMsg}`}>
                                            Buy now
                                        </Button>
                                    </Group>
                                </Stack>
                            </Card>
                        </Grid.Col>
                    </Grid>
                </SimpleGrid>
            </Container>
        </>
        }
        <Space h={"xs"} />
    </>;
}

export default function SingleProductPage() {
    const { classes } = useStyles();
    const { itemId } = useParams();
    return <>
        <Container className={classes.showcase}>
            <SingleProduct itemId={itemId} />
        </Container>
    </>
}