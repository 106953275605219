import {createStyles, rem} from "@mantine/core";

export const HEADER_HEIGHT = rem(60);
export const useStyles = createStyles((theme) => ({
    root: {
        position: 'relative',
        zIndex: 1,
        borderBottom: 0,
    },

    dd: {
        position: 'absolute',
        top: "60px",
        zIndex: 9999,
    },

    dropdown: {
        position: 'absolute',
        top: HEADER_HEIGHT,
        left: 0,
        right: 0,
        zIndex: 0,
        borderTopRightRadius: 0,
        borderTopLeftRadius: 0,
        borderTopWidth: 0,
        overflow: 'hidden',

        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
    },

    links: {
        [theme.fn.smallerThan('sm')]: {
            display: 'none',
        },
    },

    burger: {
        [theme.fn.largerThan('sm')]: {
            display: 'none',
        },
    },

    link: {
        display: 'block',
        lineHeight: 1,
        padding: `${rem(8)} ${rem(12)}`,
        borderRadius: theme.radius.sm,
        textDecoration: 'none',
        color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.colors.gray[7],
        fontSize: theme.fontSizes.sm,
        fontWeight: 500,

        '&:hover': {
            backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
        },

        [theme.fn.smallerThan('sm')]: {
            borderRadius: 0,
            padding: theme.spacing.md,
            backgroundColor: theme.fn.variant({variant: 'light', color: theme.primaryColor}).background,
        },
    },

    linkActive: {
        fontWeight: 700,
        '&, &:hover': {
            color: theme.fn.variant({variant: 'light', color: theme.primaryColor}).color,
        },
    },
}));
