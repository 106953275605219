import {createStyles, rem} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    wrapper: {
        paddingTop: rem(20),
        paddingBottom: rem(40),
    },
    ctaHeading: {
        maxWidth: `calc(100vw - 40%)`,
        width: `calc(100vw - 45%)`,
        fontSize: theme.fontSizes.xl,
        textAlign: "center",
        [theme.fn.smallerThan('lg')]: {
            maxWidth: `calc(100vw - 30%)`,
            width: `calc(100vw - 35%)`,
        },
        [theme.fn.smallerThan('md')]: {
            maxWidth: `calc(100vw - 15%)`,
            width: `calc(100vw - 20%)`,
        },
    },
    ctaText: {
        maxWidth: `calc(100vw - 40%)`,
        width: `calc(100vw - 45%)`,
        fontSize: theme.fontSizes.lg,
        textAlign: "center",
        [theme.fn.smallerThan('lg')]: {
            maxWidth: `calc(100vw - 30%)`,
            width: `calc(100vw - 35%)`,
        },
        [theme.fn.smallerThan('md')]: {
            maxWidth: `calc(100vw - 15%)`,
            width: `calc(100vw - 20%)`,
        },
    },
    ctaButton: {},

    showcase: {
        [theme.fn.largerThan('md')]: {
            paddingLeft: "5rem",
            paddingRight: "5rem",
            maxWidth: "100%",
        },
    },

    card: {
        height: rem(440),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },

    title: {
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        fontWeight: 900,
        color: theme.white,
        lineHeight: 1.2,
        fontSize: rem(32),
        marginTop: theme.spacing.xs,
    },

    collection: {
        opacity: 0.8,
        backgroundColor: theme.colors.brand[1],
        padding: 0,
        width: "100%",
        borderTopLeftRadius: theme.radius.md,
        borderTopRightRadius: theme.radius.md,
    },

    collectionName: {
        color: theme.black,
        fontWeight: 500,
        fontSize: theme.fontSizes.lg,
        paddingLeft: "1rem",
        paddingRight: "1rem",
        '&:first-of-type': {
            paddingTop: "0.5rem",
            fontSize: theme.fontSizes.md,
        },
        paddingBottom: "1rem",
    },

}));