import { Hero } from "../hero/hero";
import {
    Container, Title,
    Space, Box,
    SimpleGrid, Loader
} from "@mantine/core";
import { useStyles } from "./styles";
import { useEffect, useState } from "react";
import { ProductCard } from "../product/card/productCard";
import { Product, loadAllDesigns } from "../../data/data";

function AllProducts() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Product[]>();
    useEffect(() => {
        let once = false;
        loadAllDesigns()
            .then((data: Product[]) => {
                if (!once) {
                    setData(data);
                    setLoading(false);
                }
            });
        return () => {
            once = true;
        };
    }, []);

    return <>
        <Title order={1}>All Designs</Title>
        <Space h={"sm"} />
        <SimpleGrid cols={3} spacing="xl" verticalSpacing="xl" breakpoints={[
            { maxWidth: 'xl', cols: 3, spacing: "xl" },
            { maxWidth: 'lg', cols: 3, spacing: "lg" },
            { maxWidth: 'md', cols: 2, spacing: "md" },
            { maxWidth: 'sm', cols: 2, spacing: "sm" },
            { maxWidth: 'xs', cols: 1, spacing: "xs" },
        ]}>
            {loading && <Loader/>}
            {data?.map((item, idx) => (
                <ProductCard product={item} id={idx} key={idx} />
            ))}
        </SimpleGrid>
        <Space h={"xs"} />
    </>;
}

export default function AllProductsPage() {
    const { classes } = useStyles();
    return <>
        <Hero />
        <Space h={"xs"} />
        <Space h={"xs"} />
        <Container className={classes.showcase}>
            <AllProducts />
        </Container>
        {/*<Space h={"xl"}/>*/}
    </>
}