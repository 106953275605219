import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    card: {
        height: rem(440),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'flex-start',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        padding:0,
    },

    productImage: {
        // : 160,
        // display: 'flex',
        // alignItems: 'center',
        // justifyContent: 'center',
    },
}));