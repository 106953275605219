import {
    Text,
    Button,
    Image,
    Group,
    Badge,
    Card,
} from '@mantine/core';
import { useStyles } from "./styles";
import { Product } from '../../../data/data';
import { useNavigate } from "react-router-dom";

export function ProductCard({product, id}: {product: Product, id: Number}) {
    const { classes, cx } = useStyles();
    const navigate = useNavigate();
    return <Card shadow="sm" padding="lg" radius="md" withBorder>
        <Card.Section>
            <Image
                className={classes.productImage}
                src={product.images[0]}
                height={320}
            />
        </Card.Section>

        <Group position="apart" mt="md" mb="xs">
            <Text weight={700}>{product.collection}</Text>
            <Badge color="pink" variant="light">
                On Sale
            </Badge>
        </Group>

        <Text size="sm" color="dimmed">
            {product.description}
        </Text>

        <Group spacing={30} mt={"md"}>
            <Text fz="xl" fw={700} sx={{ lineHeight: 1 }}>
                ₹{product.price}
            </Text>

            <Button variant="light" color="blue" fullWidth radius="md" style={{ flex: 1 }} onClick={(event) => {
                event.preventDefault();
                navigate(`/shop/${id}`);
            }}>
                Buy now
            </Button>
        </Group>

    </Card>
}
