import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import {
    createBrowserRouter, Navigate,
    RouterProvider,
} from "react-router-dom";
import ErrorPage from "./errorPage";
import Home from "./components/home/home";
import AllProductsPage from "./components/designs/allProducts";
import SingleProductPage from "./components/designs/singleProduct";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <App/>,
        errorElement: <ErrorPage/>,
        children: [
            {
                path: "/",
                element: <Home/>,
                errorElement: <ErrorPage/>
            },
            {
                path: "/shop",
                element: <AllProductsPage/>,
                errorElement: <ErrorPage/>
            },
            {
                path: "/shop/:itemId",
                element: <SingleProductPage/>,
                errorElement: <ErrorPage/>
            }
        ]
    },
]);

root.render(
    <StrictMode>
        <RouterProvider router={router} />
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);