import { Button, Overlay, Group, Box } from '@mantine/core';
import { useStyles } from "./styles";
import { getHeroProductId, loadHeroImage } from '../../data/data';
import { useNavigate } from 'react-router-dom';

export function Hero() {
    const { classes, cx } = useStyles();
    const heroImageUrl = loadHeroImage();
    const navigate = useNavigate();
    return (
        <div className={classes.wrapper} style={{ backgroundImage: `url(${heroImageUrl})` }}>
            <Overlay color="#000" opacity={0.65} zIndex={1} />
            <div className={classes.inner}>
                <Group className={classes.heroControls}>
                    <Box className={classes.heroControl}>
                        <Button className={classes.heroControlButton} variant="outline" size="lg"
                            onClick={(event) => {
                                event.preventDefault();
                                navigate(`/shop/${getHeroProductId()}`);
                            }}>
                            Shop Bageecha
                        </Button>
                    </Box>
                </Group>
            </div>
        </div>
    );
}