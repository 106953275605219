import React, { useEffect, useState } from "react";
import { Carousel } from "@mantine/carousel";
import { useMediaQuery } from '@mantine/hooks';
import {
    Title,
    Button,
    useMantineTheme,
    Stack, Loader,
    Space
} from '@mantine/core';
import { useStyles } from "./styles";
import { ProductCard } from "../product/card/productCard";
import { Product, loadHomePageDesigns } from "../../data/data";

export function ProductCarousel() {
    const [loading, setLoading] = useState<boolean>(true);
    const [data, setData] = useState<Product[]>();
    const [activePage, setPage] = useState(1);
    useEffect(() => {
        let once = false;
        loadHomePageDesigns()
            .then((data: Product[]) => {
                if (!once) {
                    setData(data);
                    setLoading(false);
                }
            });
        return () => {
            once = true;
        };
    }, [activePage]);

    const { classes } = useStyles();
    const theme = useMantineTheme();
    const mobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`);

    return <>
        <Title order={1}>Latest Arrivals</Title>
        <Space h={"sm"} />
        {loading && <Loader />}
        {!loading && <Carousel
            slideSize="30%"
            breakpoints={[
                { maxWidth: 'lg', slideSize: '40%', slideGap: "md" },
                { maxWidth: 'md', slideSize: '45%', slideGap: "md" },
                { maxWidth: 'sm', slideSize: '60%', slideGap: "sm" },
                { maxWidth: 'xs', slideSize: '90%', slideGap: "xs" },
            ]}
            slideGap="md"
            align="start"
            slidesToScroll={mobile ? 1 : 2}
            loop
        >
            {data?.map((item, idx) => (
                <Carousel.Slide key={idx}>
                    <ProductCard product={item} id={idx} />
                </Carousel.Slide>
            ))}
        </Carousel>}
        <Space h={"xs"} />
        <Stack align={"center"} justify={"center"} spacing={"sm"} className={classes.wrapper}>
            <Button component="a"
                href="/shop" className={classes.ctaButton} variant="filled" size="lg">View All Designs</Button>
        </Stack>
    </>;
}