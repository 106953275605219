import {createStyles, rem} from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    wrapper: {
        position: 'relative',
        height: `calc(100vh - 100%)`,
        minHeight: `calc(100vh - 100px)`,
        // backgroundImage:
        //     'url(https://images.unsplash.com/photo-1573164713988-8665fc963095?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=980&q=80)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        zIndex: 0,
    },

    inner: {
        paddingTop: rem(240),
        paddingBottom: rem(240),
        position: 'relative',
        zIndex: 1,
    },

    heroControls: {
        marginTop: `calc(${theme.spacing.xl})`,

        display: 'flex',
        justifyContent: 'center',
        paddingLeft: theme.spacing.md,
        paddingRight: theme.spacing.md,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
            paddingLeft: 0,
            paddingRight: 0,
        },
    },

    heroControl: {
        maxWidth: `calc(100vw - 50%)`,
        width: `calc(100vw - 70%)`,
        fontSize: theme.fontSizes.md,
        backgroundColor: theme.colors.brand[0],
        [theme.fn.smallerThan('sm')]: {
            maxWidth: `calc(100vw - 0%)`,
            width: `calc(100vw - 0%)`,
            marginLeft: 0,
        },
        border: "0.0625rem solid",
        justifyContent: "center",
        display: "flex",
        paddingTop: rem(40),
        paddingBottom: rem(40),
    },

    heroControlButton: {
        height: rem(42),
        border: "0.0625rem solid",
    },
}));