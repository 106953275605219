import {ThemeProvider} from "./ThemeProvider";
import {ModalsProvider} from "@mantine/modals";
import {AppShell, Container, Header, Text} from "@mantine/core";
import React from "react";
import {Outlet, useLocation} from "react-router-dom";
import {BrandHeader} from "./components/header";
import {BrandFooter} from "./components/footer/footer";

export default function App() {
    const location = useLocation(); //#f9f9fd
    return (
        <ThemeProvider>
            <ModalsProvider>
                <AppShell
                    header={<BrandHeader links={[{link: "/", label: "Home"}, {link: "/shop", label: "Shop"}]}/> }
                    footer={<BrandFooter/>}
                >
                    <Container fluid p={0}>
                        <Outlet context={location}/>
                    </Container>
                </AppShell>

            </ModalsProvider>
        </ThemeProvider>
    );
}
