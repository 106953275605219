import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
    showcase: {
        [theme.fn.largerThan('md')]: {
            paddingLeft: "5rem",
            paddingRight: "5rem",
            maxWidth: "100%",
        },
    },
}));