import { Header, Group, Burger, Text, Container, Transition, Paper, rem } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { HEADER_HEIGHT, useStyles } from "./styles";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Logo } from '../logo/logo';
import { loadHeaderLogo } from '../../data/data';

interface HeaderResponsiveProps {
    links: { link: string; label: string }[];
}

export function BrandHeader({ links }: HeaderResponsiveProps) {
    const [opened, { toggle, close }] = useDisclosure(false);
    const [active, setActive] = useState(links[0].link);
    const { classes, cx } = useStyles();
    const navigate = useNavigate();

    const items = links.map((link) => (
        <a
            key={link.label}
            href={link.link}
            className={cx(classes.link, { [classes.linkActive]: active === link.link })}
            onClick={(event) => {
                event.preventDefault();
                setActive(link.link);
                close();
                navigate(link.link);
            }}
        >
            {link.label}
        </a>
    ));
    const imageUrl = loadHeaderLogo();
    return (
        <Header height={HEADER_HEIGHT} mb={0} className={classes.root}>
            <Container className={classes.header}>
                <Burger opened={opened} onClick={toggle} className={classes.burger} size="sm" />
                <Logo size='xl'
                    height={`calc(${HEADER_HEIGHT} - ${rem(10)})`}
                    width={`calc(${HEADER_HEIGHT}*2)`}
                    imageUrl={imageUrl}
                    onClick={() => {
                        navigate('/');
                    }}
                    style={{cursor: "pointer"}}
                />
                <Group spacing={5} className={classes.links}>
                    {items}
                </Group>
                <Transition transition="pop-top-left" duration={100} mounted={opened}>
                    {(transitionStyle) => (
                        <Paper
                            className={classes.dropdown}
                            withBorder
                            style={{ ...transitionStyle }}>
                            {items}
                        </Paper>
                    )}
                </Transition>
            </Container>
        </Header>
    );
}